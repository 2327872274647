import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { QuoteText } from '../components/content/Citation'
import ColoredBox from '../components/content/ColoredBox'
import ContentButton from '../components/content/ContentButton'
import ContentImage from '../components/content/ContentImage'
import RenderHtml from '../components/content/RenderHtml'
import withTranslations from '../components/hoc/withTranslations'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import { ISuccessStoryTemplateData } from '../types/SuccessStory'
import Translation from '../types/Translation'

const SuccessStoryPageTemplate: React.FunctionComponent<SuccessStoryPageTemplateProps> = (
    props: SuccessStoryPageTemplateProps,
) => {
    const { pageContext, t } = props

    const { templateData, language } = pageContext

    if (!templateData) {
        return null
    }

    const { title, illustration, content, customer, partner, products, citations, newsletterSubscribeUrl } = templateData

    return (
        <MainLayout pageContext={pageContext}>
            <Container>
                <Row className="mb-4">
                    <Col sm={12}>
                        <p className="h3 underlined">{t('template', 'successstory.page.title.testimonial')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Row>
                            <Col sm={12}>
                                <h1>{title}</h1>
                            </Col>
                        </Row>
                        {illustration?.src && (
                            <Row className="mb-4">
                                <Col sm={12}>
                                    <ContentImage image={illustration} fluid />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {content.requirements && (
                                <Col sm={6}>
                                    <h2>{t('template', 'successstory.textbox.title.requirements')}</h2>
                                    <RenderHtml html={content.requirements} />
                                </Col>
                            )}
                            {content.benefits && (
                                <Col sm={6}>
                                    <h2>{t('template', 'successstory.textbox.title.benefits')}</h2>
                                    <RenderHtml html={content.benefits} />
                                </Col>
                            )}
                        </Row>
                        {content.implementation && (
                            <Row>
                                <Col sm={6}>
                                    <h2>{t('template', 'successstory.textbox.title.implementation')}</h2>
                                    <RenderHtml html={content.implementation} />
                                </Col>
                            </Row>
                        )}
                    </Col>

                    <Col sm={4}>
                        <Row className="mb-4">
                            <Col sm={12}>
                                {products.map((product, i) => (
                                    <ContentButton
                                        key={`btn-${i}`}
                                        to={product.url}
                                        pdftColor={product.color}
                                        block={true}
                                        disabled={!product.url}
                                    >
                                        {product.name}
                                    </ContentButton>
                                ))}
                                {newsletterSubscribeUrl && (
                                    <ContentButton to={newsletterSubscribeUrl} pdftColor="blue" block={true}>
                                        {t('template', 'newsletter.button.subscribe.label')}
                                    </ContentButton>
                                )}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col sm={12}>
                                {customer?.logo && <ContentImage image={customer.logo} fluid />}
                                {partner?.logo && <ContentImage image={partner.logo} fluid />}
                            </Col>
                        </Row>
                        {citations.length > 0 && (
                            <Row>
                                <Col sm={12}>
                                    <ColoredBox pdftColor="blue">
                                        <h2 className="underlined">{t('template', 'successstory.customerstatements.title')}</h2>
                                        {citations.map((citation) => (
                                            <>
                                                <p className="font-italic">{QuoteText(citation.cite, language)}</p>
                                                <p>
                                                    {citation.author},<br />
                                                    {citation.position}, {citation.company}
                                                </p>
                                            </>
                                        ))}
                                    </ColoredBox>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        {customer?.portrait && (
                            <Row>
                                <Col sm={12}>
                                    <h3>
                                        {t('template', 'successstory.portrait.title')} {customer.name}
                                    </h3>
                                    <RenderHtml html={customer.portrait} />
                                </Col>
                            </Row>
                        )}

                        {partner?.portrait && (
                            <Row>
                                <Col sm={12}>
                                    <h3>
                                        {t('template', 'successstory.portrait.title')} {partner.name}
                                    </h3>
                                    <RenderHtml html={partner.portrait} />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
}

export default withTranslations(SuccessStoryPageTemplate)

interface SuccessStoryPageTemplateProps extends Translation {
    pageContext: PageContext<ISuccessStoryTemplateData>
}
